<template>
  <div class="editproductData dataM">
    <div class="content">
      <div class="title">
        产品基本信息
      </div>
      <div class="info">
        <div class="item" v-for="(value,key,index) in details" :key="index">
          <label>{{value}}：</label>
          <span>{{info[key]}}</span>
        </div>
      </div>
    </div>
    <div class="process">
      <div class="title">
        工序列表
        <div class="tool">
          <!-- <img src="@/assets/images/dataManage/add.png"/> -->
          <el-input
            v-model="searchValue"
            style="width:220px"
            size="small"
            placeholder="请输入工步名称/编号"
            @keydown.enter.native="getProcessData(1)"
            >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="getProcessData(1)"></i>
          </el-input>
        </div>
      </div>
      <el-table :data="tableData" header-cell-class-name="tableHeader" style="width: 100%">
        <el-table-column label="序号" type="index" width="80">
          <template slot-scope="scope">
            <span>{{((page-1)*10+scope.$index+1) < 10 ? `0${(page-1)*10+scope.$index+1}` : (page-1)*10+scope.$index+1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="department" label="执行部门" min-width="120"/>
        <el-table-column prop="procedureName" label="工步名称" min-width="120"/>
        <el-table-column prop="priority" label="工步优先级" min-width="120"/>
        <el-table-column prop="processingType" label="设备类型" min-width="120"/>
        <el-table-column prop="procedureNo" label="工步编号" min-width="120"/>
        <el-table-column prop="procedureTime" label="工步时间(min)" min-width="150"/>
        <el-table-column prop="firstArticleInspectionsTime" label="首件送检时间(min)" min-width="180"/>
        <el-table-column prop="shuntingTime" label="调机时间(min)" min-width="150"/>
        <el-table-column prop="checkFactor" label="抽检系数" min-width="120"/>
        <el-table-column prop="processingMethod" label="产出方式（一出几）" min-width="200"/>
        <el-table-column prop="usedMachine" label="是否可用机器人" min-width="180">
          <template slot-scope="scope">
            <span>{{scope.row.usedMachine===0?'否':(scope.row.usedMachine===1?'是':scope.row.usedMachine)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cutter" label="刀具" min-width="120"/>
        <el-table-column prop="fixture" label="夹具" min-width="120"/>
        <el-table-column prop="testingFixture" label="检具" min-width="120"/>
        <el-table-column prop="procedureMateriaNo" label="工步物料编码" min-width="150"/>
        <el-table-column label="操作" fixed="right" width="120" align="center">
          <template slot-scope="scope">
            <div class="operation">
              <!-- <img src="@/assets/images/dataManage/edit.png" style="margin-right:10px" @click="edit(scope.row)"/> -->
              <!-- <el-tooltip effect="dark" content="删除" placement="top">
                <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
              </el-tooltip> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="getProcessData"/>
    </div>
  </div>
</template>

<script>
const pagination = () => import('@/components/pagination')
import { getProductDetail,getProcedureInfo,delProcedure} from '@/api/data.js'
import { isComfirm } from '@/utils/data.js'
export default {
  name:"editproductData",
  components:{
    pagination
  },
  data(){
    return{
      details:{
        "partCode": "图 号",
        "productName": "物料名称",//
        "materialNo": "物料编码",//
        "versionCode": "版本号",
        "customerName": "客户",
      },
      info:{
        "partCode": "",
        "productName": "",//
        "materialNo": "",//
        "versionCode": "",
        "customerName": "",
      },
      tableData:[],
      searchValue:'',
      total:0,
      page:1,
      size:10,
      routeData:{}
    }
  },
  watch:{
    size(){
      this.getProcessData(1)
    }
  },
  activated(){
    this.routeData = this.$route.query
    this.getDetail()
    this.getProcessData()
  },
  methods:{
    //产品详情
    getDetail(){
      getProductDetail(this.routeData.id).then(res=>{
        if(res.code === 0){
          this.info = res.data
        }
      })
    },
    //工序列表
    getProcessData(page){
      page ? this.page = page : this.page = 1;
      let params = {
        page:this.page,
        size:this.size,
        partCode:this.routeData.partCode,
        partVersion:this.routeData.partVersion,
        searchValue :this.searchValue
      }
      getProcedureInfo(params).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    comfirm(row){
      isComfirm(row,this.del)
    },
    del(row){
      delProcedure(row.id).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.getProcessData()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    }
  }
}
</script>

<style lang="scss">
.editproductData{
  text-align: left;
  padding: 18px;
  color: #E8E8E8;
  img{
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .title{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .content{
    background: #232323;
    padding: 25px 35px;
    .info{
      display: flex;
      flex-wrap: wrap;
      .item{
        width: 30%;
        label{
          display: inline-block;
          min-width: 80px;
          max-width: max-content;
        }
      }
    }
  }
  .process{
    background: #232323;
    padding: 25px 35px;
    margin-top:10px;
    min-height: calc(100vh - 440px);
    position: relative;
    padding: 25px 35px 80px 35px;
    .title{
      display: flex;
      justify-content: space-between;
    }
    .tool{
      width: 300px;
      display: flex;
      align-items: center;
      img{
        width: 22px;
        height: 22px;
        margin: 0 15px;
        cursor: pointer;
      }
    }
    .tableHeader{
      background: #2C2C2C;
      color: #B9B9B9;
    }
  }
}
</style>